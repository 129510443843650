import React, { useState } from "react"
import { Box, Divider, Tab, Tabs, makeStyles, styled } from "@material-ui/core"
import { ActivityForm } from "./components/ActivityForm"
import ReinsuranceBlock from "components/reinsuranceBlock"

export const ActivitiesHeroBlock = ({
  activities,
  products,
  isPreview,
  displayHeaderBanner,
}) => {
  const [activeTab, setActiveTab] = useState(0)
  let classes = useStyle({ activities, activeTab })

  const BackgroundBox = styled(Box)(({ theme }) => ({
    height: "max(90vh, 750px)",
    padding: "0px 2% 0 6%",
    display: "grid",
    gridColumnGap: theme.spacing(3),
    gridTemplateColumns: "3fr 2fr",
    background: "#F3BEBE",
    "@media(max-width: 992px)": {
      display: "block",
      padding: "150px 0 100px 0",
    },
    "@media(max-width: 359px)": {
      padding: "112px 0 80px 0",
    },
    [theme.breakpoints.down("xs")]: {
      height: "unset",
    },
  }))

  return (
    <>
      <BackgroundBox>
        <Box py={3} className={classes.whiteCard}>
          <Tabs
            onChange={(event, value) => {
              setActiveTab(value)
            }}
            value={activeTab}
            classes={{
              flexContainer: classes.flexBox,
              indicator: classes.indicator,
            }}
          >
            {activities.map((activity, index) => (
              <Tab
                key={index}
                value={index}
                classes={{ root: classes.tab }}
                label={
                  <Box textAlign="center">
                    <Box className={classes.picto}>
                      <img width={22} src={activity.picto.publicURL} />
                    </Box>
                    <Box mb={2}>{activity.tabTitle}</Box>
                  </Box>
                }
                id={activity.slug}
              />
            ))}
          </Tabs>
          <Box className={classes.divider}>
            <Divider />
          </Box>
          {activities.map((activity, index) => (
            <ActivityForm
              key={activity.slug}
              activity={activity}
              products={products}
              isActive={index === activeTab}
              isPreview={isPreview}
              displayHeaderBanner={displayHeaderBanner}
            />
          ))}
        </Box>
        <Box maxWidth={"450px"} pt={3} className={classes.imageBox}>
          <img
            className={classes.image}
            src={activities[activeTab].backgroundImage.publicURL}
          />
        </Box>
      </BackgroundBox>
      <Box className={classes.overflowOnMobile}>
        <ReinsuranceBlock {...activities[activeTab]} />
      </Box>
    </>
  )
}

export default ActivitiesHeroBlock

const useStyle = makeStyles(theme => ({
  overflowOnMobile: {
    [theme.breakpoints.down("xs")]: { marginTop: -30, marginBottom: -30 },
  },
  imageBox: {
    margin: "auto",
    "@media(max-width: 1200px)": {
      display: "none",
    },
  },
  image: {
    maxWidth: "inherit",
  },
  whiteCard: {
    maxWidth: "780px",
    margin: "auto",
    backgroundColor: "white",
    borderRadius: 12,
    height: "fit-content",
    "@media(max-width: 992px)": {
      width: "80%",
      margin: "auto",
    },
    "@media(max-width: 480px)": {
      width: "95%",
      paddingTop: theme.spacing(2),
    },
  },
  picto: {
    maxHeight: 24,
    maxWidth: 24,
    alignSelf: "center",
    margin: "auto",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
    },
  },
  flexBox: {
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
  indicator: {
    height: 4,
  },
  tab: {
    padding: "4px 12px",
    width: "25%",
    color: theme.palette.secondary.main,
    textTransform: "unset",
    fontWeight: 500,
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      width: "unset",
      minWidth: "20%",
    },
  },
}))
